exports.components = {
  "component---src-pages-13-f-details-[cik]-tsx": () => import("./../../../src/pages/13f/details/[cik].tsx" /* webpackChunkName: "component---src-pages-13-f-details-[cik]-tsx" */),
  "component---src-pages-13-f-index-tsx": () => import("./../../../src/pages/13f/index.tsx" /* webpackChunkName: "component---src-pages-13-f-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-calendar-tsx": () => import("./../../../src/pages/calendar.tsx" /* webpackChunkName: "component---src-pages-calendar-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-currencies-details-[symbol]-tsx": () => import("./../../../src/pages/currencies/details/[symbol].tsx" /* webpackChunkName: "component---src-pages-currencies-details-[symbol]-tsx" */),
  "component---src-pages-currencies-screener-tsx": () => import("./../../../src/pages/currencies/screener.tsx" /* webpackChunkName: "component---src-pages-currencies-screener-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-etfs-details-[symbol]-tsx": () => import("./../../../src/pages/etfs/details/[symbol].tsx" /* webpackChunkName: "component---src-pages-etfs-details-[symbol]-tsx" */),
  "component---src-pages-etfs-screener-tsx": () => import("./../../../src/pages/etfs/screener.tsx" /* webpackChunkName: "component---src-pages-etfs-screener-tsx" */),
  "component---src-pages-explore-tsx": () => import("./../../../src/pages/explore.tsx" /* webpackChunkName: "component---src-pages-explore-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indices-details-[symbol]-tsx": () => import("./../../../src/pages/indices/details/[symbol].tsx" /* webpackChunkName: "component---src-pages-indices-details-[symbol]-tsx" */),
  "component---src-pages-indices-screener-tsx": () => import("./../../../src/pages/indices/screener.tsx" /* webpackChunkName: "component---src-pages-indices-screener-tsx" */),
  "component---src-pages-learnings-index-tsx": () => import("./../../../src/pages/learnings/index.tsx" /* webpackChunkName: "component---src-pages-learnings-index-tsx" */),
  "component---src-pages-learnings-notion-fields-category-slug-index-tsx": () => import("./../../../src/pages/learnings/{Notion.fields__categorySlug}/index.tsx" /* webpackChunkName: "component---src-pages-learnings-notion-fields-category-slug-index-tsx" */),
  "component---src-pages-learnings-notion-fields-category-slug-notion-fields-post-slug-tsx": () => import("./../../../src/pages/learnings/{Notion.fields__categorySlug}/{Notion.fields__postSlug}.tsx" /* webpackChunkName: "component---src-pages-learnings-notion-fields-category-slug-notion-fields-post-slug-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-payment-cancel-tsx": () => import("./../../../src/pages/payment/cancel.tsx" /* webpackChunkName: "component---src-pages-payment-cancel-tsx" */),
  "component---src-pages-payment-index-tsx": () => import("./../../../src/pages/payment/index.tsx" /* webpackChunkName: "component---src-pages-payment-index-tsx" */),
  "component---src-pages-payment-success-tsx": () => import("./../../../src/pages/payment/success.tsx" /* webpackChunkName: "component---src-pages-payment-success-tsx" */),
  "component---src-pages-portfolios-[id]-tsx": () => import("./../../../src/pages/portfolios/[id].tsx" /* webpackChunkName: "component---src-pages-portfolios-[id]-tsx" */),
  "component---src-pages-portfolios-index-tsx": () => import("./../../../src/pages/portfolios/index.tsx" /* webpackChunkName: "component---src-pages-portfolios-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-senate-index-tsx": () => import("./../../../src/pages/senate/index.tsx" /* webpackChunkName: "component---src-pages-senate-index-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-stocks-details-[symbol]-tsx": () => import("./../../../src/pages/stocks/details/[symbol].tsx" /* webpackChunkName: "component---src-pages-stocks-details-[symbol]-tsx" */),
  "component---src-pages-stocks-dividends-growth-tsx": () => import("./../../../src/pages/stocks/dividends/growth.tsx" /* webpackChunkName: "component---src-pages-stocks-dividends-growth-tsx" */),
  "component---src-pages-stocks-dividends-upcoming-tsx": () => import("./../../../src/pages/stocks/dividends/upcoming.tsx" /* webpackChunkName: "component---src-pages-stocks-dividends-upcoming-tsx" */),
  "component---src-pages-stocks-gainers-losers-tsx": () => import("./../../../src/pages/stocks/gainers-losers.tsx" /* webpackChunkName: "component---src-pages-stocks-gainers-losers-tsx" */),
  "component---src-pages-stocks-investorean-tsx": () => import("./../../../src/pages/stocks/investorean.tsx" /* webpackChunkName: "component---src-pages-stocks-investorean-tsx" */),
  "component---src-pages-stocks-penny-tsx": () => import("./../../../src/pages/stocks/penny.tsx" /* webpackChunkName: "component---src-pages-stocks-penny-tsx" */),
  "component---src-pages-stocks-peter-lynch-tsx": () => import("./../../../src/pages/stocks/peter-lynch.tsx" /* webpackChunkName: "component---src-pages-stocks-peter-lynch-tsx" */),
  "component---src-pages-stocks-screener-tsx": () => import("./../../../src/pages/stocks/screener.tsx" /* webpackChunkName: "component---src-pages-stocks-screener-tsx" */),
  "component---src-pages-stocks-warren-buffett-tsx": () => import("./../../../src/pages/stocks/warren-buffett.tsx" /* webpackChunkName: "component---src-pages-stocks-warren-buffett-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-watchlists-[id]-tsx": () => import("./../../../src/pages/watchlists/[id].tsx" /* webpackChunkName: "component---src-pages-watchlists-[id]-tsx" */),
  "component---src-pages-watchlists-index-tsx": () => import("./../../../src/pages/watchlists/index.tsx" /* webpackChunkName: "component---src-pages-watchlists-index-tsx" */)
}

