import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import {
  type AxiosCacheInstance,
  setupCache,
  type CacheRequestConfig,
  type CacheAxiosResponse,
} from 'axios-cache-interceptor';
import { getAuth } from 'firebase/auth';
import { getToken } from 'firebase/app-check';
import { toaster, Message } from 'rsuite';
import { env, isBrowser } from './env';
import Logger from './Logger';
import { appCheck } from '../context/wrapRootElement';

class ApiClient {
  private client: AxiosCacheInstance;

  static displayError(_error: Error) {
    if (!isBrowser()) {
      return;
    }
    toaster.push(
      <Message showIcon type="error" title="Error">
        Oops! We're couldn't reach our servers... Please refresh the page or
        contact our support if the issue is consistent.
      </Message>,
      { placement: 'topEnd', duration: 5000 },
    );
  }

  public constructor() {
    const instance = axios.create({
      baseURL: `//${env.apiHost}`,
    });
    instance.interceptors.response.use(
      (response) => response,
      ApiClient.displayError,
    );
    this.client = setupCache(instance, {
      methods: ['get', 'post', 'options', 'head'],
      ttl: 3000,
    });
  }

  private async getAppCheckToken(): Promise<string | null> {
    try {
      if (!appCheck) {
        throw new Error('AppCheck is not initialized');
      }
      const { token } = await getToken(appCheck, false);
      return token;
    } catch (e) {
      Logger.error(e);
      return null;
    }
  }

  public async post<REQUEST, RESPONSE>(
    url: string,
    data: REQUEST,
    config?: AxiosRequestConfig & CacheRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    const appCheckToken = await this.getAppCheckToken();
    const userIdToken = await getAuth().currentUser?.getIdToken();
    const response = await this.client.post<
      RESPONSE,
      REQUEST,
      CacheAxiosResponse<RESPONSE, REQUEST>
    >(url, data, {
      ...config,
      headers: {
        ...config?.headers,
        ['X-Firebase-AppCheck'.toLowerCase()]:
          appCheckToken || 'token-placeholder',
        'inv-uid-token': userIdToken,
      },
    });

    return response;
  }
}

export default new ApiClient();
